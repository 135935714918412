.Table {
  width: 100%;
  margin-top: 15px;
  border: none;
}

.headerRow {

  border-bottom: 1px solid #000000 !important;
  border-radius: unset;
}

.headerRow,
.evenRow,
.oddRow {
  border: none;
  border-top: none;
}
.evenRow:hover,
.oddRow:hover {
  background: rgba(0,0,0,.05)!important;
  color: rgba(0,0,0,.95)!important;
}

.oddRow {
  background-color: #e5e5e5;
}

.headerColumn {
  text-transform: none;
}

.bodyColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyColumn.dropdown {
  overflow: visible !important;
}
